import React, { FC, useContext, useEffect } from 'react'
import { AppDispatch } from '@services/store/type'
import { useAppDispatch } from '@services/store'
import { doOpenSearchHeader } from '@services/store/articles'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import Authentication, {
	AuthenticationActionType,
	AuthenticationType
} from '@components/auth/Authentication'
import { PageTitle } from '@components/configs/PageTitle'
import SectionTitle from '@components/ui/sectionTitle'
import {
	formatHtmlForStrapiText,
	formatStrapiText,
	onNavigateToOtherSubjectRequest
} from '@utils/methods'
import BlueBulletsList from '@components/ui/blueBulletsList'
import Text from '@components/ui/text'
import Collapse from '@components/ui/collapse'
import Link from '@components/ui/link'
import config from '@utils/config'
import * as pageUtils from '@pages/faq/__index.utils'

const Requests: FC = () => {
	const { pageData } = useContext(AppStateContext)
	const { authenticationAction, authenticationModal }: AuthenticationType =
		Authentication()

	const dispatch: AppDispatch = useAppDispatch()

	const { signin, signup } = config.auth
	const { searchLinkText } = config.articles
	const labels = [
		{
			name: pageData.assets?.faqPage_create_and_secure_your_accound_in_my_file,
			questions: [
				{
					name: pageData.assets?.faqPage_how_do_I_create_mon_dossier,
					answer: pageData.assets?.faqPage_answer_how_do_I_create_mon_dossier
				},
				{
					name: pageData.assets
						?.faqPage_is_there_a_support_service_for_creating_an_account_and_a_request,
					answer:
						pageData.assets
							?.faqPage_answer_is_there_a_support_service_for_creating_an_account_and_a_request
				},
				{
					name: pageData.assets
						?.faqPage_is_it_necessary_to_have_an_email_address_to_create_my_account,
					answer:
						pageData.assets
							?.faqPage_answer_is_it_necessary_to_have_an_email_address_to_create_my_account
				},
				{
					name: pageData.assets?.faqPage_how_do_I_update_my_profile,
					answer: pageData.assets?.faqPage_answer_how_do_I_update_my_profile
				}
			]
		},
		{
			name: pageData.assets?.faqPage_create_and_track_your_request,
			questions: [
				{
					name: pageData.assets
						?.faqPage_question_what_information_is_contained_in_the_311_information_databank,
					answer:
						pageData.assets
							?.faqPage_answer_what_information_is_contained_in_the_311_information_databank
				},
				{
					name: pageData.assets?.faqPage_how_do_i_create_a_request,
					answer: pageData.assets?.faqPage_answer_how_do_i_create_a_request
				},
				{
					name: pageData.assets
						?.faqPage_question_how_do_I_track_an_existing_request,
					answer:
						pageData.assets?.faqPage_answer_how_do_I_track_an_existing_request
				},
				{
					name: pageData.assets
						?.faqPage_question_how_do_I_track_the_progress_of_my_request,
					answer:
						pageData.assets
							?.faqPage_answer_how_do_I_track_the_progress_of_my_request
				},
				{
					name: pageData.assets
						?.faqPage_question_what_are_the_types_of_requests_that_can_be_transmitted_through_mon_dossier,
					answer:
						pageData.assets
							?.faqPage_answer_what_are_the_types_of_requests_that_can_be_transmitted_through_mon_dossier
				},
				{
					name: pageData.assets
						?.faqPage_question_how_do_I_modify_or_cancel_a_request,
					answer:
						pageData.assets?.faqPage_answer_how_do_I_modify_or_cancel_a_request
				},
				{
					name: pageData.assets
						?.faqPage_question_how_do_i_choose_to_be_notified_by_email_about_my_requests,
					answer:
						pageData.assets
							?.faqPage_answer_how_do_i_choose_to_be_notified_by_email_about_my_requests
				},
				{
					name: pageData.assets
						?.faqPage_question_how_can_i_share_my_request_information_with_an_elected_official,
					answer:
						pageData.assets
							?.faqPage_answer_how_can_i_share_my_request_information_with_an_elected_official
				}
			]
		},
		{
			name: pageData.assets?.faqPage_the_verification_code_and_your_password,
			questions: [
				{
					name: pageData.assets
						?.faqPage_question_how_do_i_recover_a_forgotten_password_or_change_it,
					answer:
						pageData.assets
							?.faqPage_answer_how_do_i_recover_a_forgotten_password_or_change_it
				},
				{
					name: pageData.assets
						?.faqPage_question_what_can_i_do_if_i_did_not_receive_an_email_with_the_verification_code_when_creating_my_account_resetting_my_forgotten_password_or_changing_it,
					answer:
						pageData.assets
							?.faqPage_answer_what_can_i_do_if_i_did_not_receive_an_email_with_the_verification_code_when_creating_my_account_resetting_my_forgotten_password_or_changing_it
				},
				{
					name: pageData.assets?.faqPage_what_can_I_do_to_protect_my_account,
					answer:
						pageData.assets?.faqPage_answer_what_can_I_do_to_protect_my_account
				}
			]
		},
		{
			name: pageData.assets?.faq_deactivate_your_account_in_my_file,
			questions: [
				{
					name: pageData.assets?.faqPage_question_how_to_deactivate_my_account,
					answer: pageData.assets.faqPage_answer_how_to_deactivate_my_account
				},
				{
					name: pageData.assets
						?.faqPage_how_do_I_know_if_my_account_has_been_deactivated,
					answer:
						pageData.assets
							.faq_answer_how_do_I_know_if_my_account_has_been_deactivated
				}
			]
		}
	]
	const labelsClone = JSON.parse(JSON.stringify(labels))
	labelsClone.unshift({
		name: pageData.assets?.faqPage_what_can_i_do_in_my_file,
		questions: [{ name: '', answer: '' }]
	})

	useEffect(() => {
		document
			.querySelector('#questions-container')
			?.querySelectorAll('a')
			.forEach((link) => {
				const href = link.getAttribute('href')

				if (href === signin || href === signup) {
					link.addEventListener(
						'click',
						authenticationAction(href as AuthenticationActionType)
					)

					return
				}

				if (href === searchLinkText) {
					link.addEventListener('click', (e) => {
						e.preventDefault()
						dispatch(doOpenSearchHeader(true))
					})

					return
				}

				if (href?.startsWith('/')) {
					return
				}

				link.setAttribute('target', '_blank')
			})
		document
			.querySelector(`.${pageUtils.classes.box}`)
			?.querySelectorAll('a')
			.forEach((link) => {
				link.addEventListener('click', () => {
					setTimeout(() => {
						window.scrollBy({
							top: -100,
							behavior: 'smooth'
						})
					}, 100)
				})
			})
	}, [])

	return (
		<>
			<PageTitle title={formatStrapiText(pageData?.title)} />
			<SectionTitle title={formatStrapiText(pageData?.title)} />
			<section className={pageUtils.classes.section}>
				<div className={pageUtils.classes.container}>
					<div
						className={pageUtils.classes.column1}
						id={`${pageData.assets?.faqPage_what_can_i_do_in_my_file}`}
					>
						<div>
							<h2
								className={pageUtils.classes.h2}
								style={{ marginTop: '10px' }}
							>
								{formatStrapiText(
									pageData.assets?.faqPage_what_can_i_do_in_my_file
								)}
							</h2>
							<div className={pageUtils.classes.section1}>
								<BlueBulletsList text>
									<Text
										content={formatHtmlForStrapiText(
											pageData.assets
												?.faqPage_answer_faqPage_what_can_i_do_in_my_file
										)}
									/>
								</BlueBulletsList>
							</div>
						</div>
						<div id="questions-container">
							{labels.map((label, index) => {
								return (
									<div key={`l-${index}`} id={`${label.name}`}>
										<h2
											className={pageUtils.classes.h2}
											style={{ margin: '50px 0 15px' }}
										>
											{formatStrapiText(label.name)}
										</h2>
										{label.questions?.map((question, key) => (
											<div
												key={`q-${key}`}
												className={pageUtils.classes.accordeonContainer}
											>
												<div className={pageUtils.classes.collapseListItem}>
													<Collapse id={key} buttonText={question.name}>
														<Text content={question.answer} />
													</Collapse>
												</div>
											</div>
										))}
									</div>
								)
							})}
						</div>
					</div>
					<div className={pageUtils.classes.column2}>
						<div className={pageUtils.classes.box}>
							<div className={pageUtils.classes.secondaryColorBox}>
								{formatStrapiText(pageData?.assets.faqPage_in_this_page)}
							</div>
							<BlueBulletsList labels={labelsClone} />
						</div>
						<div
							className={`${pageUtils.classes.secondaryColorBox} ${pageUtils.classes.boxQuestion}`}
						>
							<p style={{ fontWeight: 'normal' }}>
								{formatStrapiText(
									pageData.assets?.faqPage_cant_find_what_youre_looking_for
								)}
							</p>
							<Link
								to="/auth/requests/create/other-subject"
								className={pageUtils.classes.buttonOutline}
								onClick={onNavigateToOtherSubjectRequest()}
							>
								{formatStrapiText(pageData.assets?.label_write_us)}
							</Link>
						</div>
					</div>
				</div>
			</section>
			{authenticationModal()}
		</>
	)
}

export default Requests
